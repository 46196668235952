import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import React, { useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  Icon,
  Button,
  Label,
  Link,
  Loading,
  Text,
} from '@workwhile/ui'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import {
  Calendar,
  Clock,
  Crown,
  DollarSign,
  ExternalLink,
  MapPin,
  User,
  User2Icon,
} from 'lucide-react'

import Pay from './Pay'
import { useShiftDetails } from 'queries/shifts/useShiftDetails'
import { WorkerRequirement, WorkShift } from 'api/typings'
import api from 'api'
import RefreshButton from 'components/Shared/RefreshButton'
import { SHIFT_SEARCH } from 'constants/routes'
import Workers from './Workers'
import Listings from './Listings'
import { defs } from 'components/Shared/defs'
import ComposeMessageModal from 'components/messaging2/ComposeMessageModal'

const QueryHeading = ({
  children,
  query,
  level,
}: React.PropsWithChildren<{
  query: UseQueryResult
  level: string | number
}>) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Heading level={level}>{children}</Heading>
    {(!!query.data || query.isError) && !query.isFetching && (
      <RefreshButton refreshFn={query.refetch} loading={query.isFetching} />
    )}
    {query.isFetching && <Loading />}
  </Flex>
)

const formatAddress = (address) =>
  address
    ? `${address.street}, ${address.city}, ${address.state}, ${address.zip}`
    : ''

const getMustHaveRequirements = (shift: WorkShift) => {
  if (!shift?.position) return []

  const {
    needsCar,
    needsFullTime,
    needsLicense,
    needsW2,
    isDrivingPosition,
    mustHaveRequirements,
  } = shift.position

  return [
    needsCar && {
      name: 'Needs car',
    },
    needsFullTime && {
      name: 'Needs full time',
    },
    needsLicense && {
      name: 'Needs license',
    },
    needsW2 && {
      name: 'Needs W2',
    },
    isDrivingPosition && {
      name: 'Is driving position',
    },
    ...(mustHaveRequirements || []),
  ].filter(Boolean) as WorkerRequirement[]
}

const ShiftDetail = () => {
  const [showCallListModal, setShowCallListModal] = useState(false)
  const { id: shiftId } = useParams<{ id: string }>()

  /** Use search results cache to power prev/next navigation */
  const queryClient = useQueryClient()
  const cache = queryClient.getQueriesData<WorkShift[]>({
    queryKey: ['shifts'],
  })
  const [_queryKey, shifts] =
    cache?.find(([key, result]) =>
      result?.find((shift) => Number(shift.id) === Number(shiftId))
    ) || []
  const shiftIndex = shifts?.findIndex(
    (shift) => Number(shift.id) === Number(shiftId)
  )
  const navigate = useNavigate()

  /** Data */
  const shift = useShiftDetails(shiftId, 'detail')

  const [isRelative, setIsRelative] = useState(true)

  if (!shiftId) {
    redirect('/shifts')
  }

  const handleNavigate = (diff) =>
    navigate(`/shifts/${shifts[shiftIndex + diff].id}`)

  const handleSwitchCompany = (onSwitch) => async () => {
    if (!shift.data?.company?.id) return

    try {
      await api.put(`/admin/company/${shift.data.company.id}/switch`)
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        // On mobile devices, replace current tab's location
        window.location.replace('https://business.workwhilejobs.com')
      } else {
        // On non-mobile devices, open a new tab
        onSwitch()
      }
    } catch (error) {
      console.error('Error switching company:', error)
    }
  }

  const openDashboard = () => {
    window.open('https://business.workwhilejobs.com', '_blank')
  }

  const openAssignment = () => {
    window.open(
      `https://business.workwhilejobs.com/assignment/${shift.data.assignment.id}`,
      '_blank'
    )
  }

  const openShift = () => {
    window.open(
      `https://business.workwhilejobs.com/shift/${shift.data.id}`,
      '_blank'
    )
  }

  const mustHaveRequirements = getMustHaveRequirements(shift.data)

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Box>
          <Link to={SHIFT_SEARCH}>Search Shifts</Link> {' > '}Shift Detail
        </Box>
        {!!shifts?.length && (
          <Flex>
            {!!shifts[shiftIndex - 1] && (
              <Button
                variant="primary"
                width="100px"
                onClick={() => handleNavigate(-1)}
              >
                Previous
              </Button>
            )}
            {!!shifts[shiftIndex + 1] && (
              <Button
                variant="primary"
                width="100px"
                onClick={() => handleNavigate(1)}
                ml="2"
              >
                Next
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      <QueryHeading level="1" query={shift}>
        <Flex alignItems="center">
          Shift ({shiftId})
          <Button
            ml="2"
            kind="small"
            variant="text"
            onClick={handleSwitchCompany(openShift)}
          >
            Shift Detail
            <Icon
              style={{ marginLeft: '5px' }}
              size={15}
              color="primary"
              icon={ExternalLink}
            />
          </Button>
          {!!shift.data?.cancelledAt && (
            <Label ml="2" variant="error">{`CANCELLED`}</Label>
          )}
          {!!shift.data?.listings?.[0]?.freeze && (
            <Label ml="2" variant="information">{`FROZEN`}</Label>
          )}
          {!!shift.data?.isTryout && (
            <Label ml="2" variant="neutral">{`TRYOUT`}</Label>
          )}
        </Flex>
      </QueryHeading>
      <Flex
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        style={{ columnGap: defs.marginL }}
      >
        <Box flex="1 1 100%">
          {shift.data && (
            <Box key="companyName">
              {shift.data.company && (
                <Heading level={2}>
                  {shift.data?.company?.name} ({shift.data?.company?.id})
                  <Button
                    ml="2"
                    kind="small"
                    variant="text"
                    onClick={handleSwitchCompany(openDashboard)}
                  >
                    Dashboard
                    <Icon
                      style={{ marginLeft: '5px' }}
                      size={15}
                      color="primary"
                      icon={ExternalLink}
                    />
                  </Button>
                </Heading>
              )}

              <Box key="shiftDetails">
                {shift.data?.position && (
                  <Flex alignItems="center" my="1">
                    <Icon icon={User2Icon} size={20} />
                    <Text ml="2">
                      {shift.data?.position?.name} ({shift.data?.position?.id})
                    </Text>
                  </Flex>
                )}
                {shift.data?.assignment && (
                  <Flex alignItems="center" my="1">
                    <Icon icon={Calendar} size={20} />
                    <Text ml="2">
                      {shift.data?.assignment?.name} (
                      {shift.data?.assignment?.id})
                      <Button
                        ml="2"
                        kind="small"
                        variant="text"
                        onClick={handleSwitchCompany(openAssignment)}
                      >
                        Assignment
                        <Icon
                          style={{ marginLeft: '5px' }}
                          size={15}
                          color="primary"
                          icon={ExternalLink}
                        />
                      </Button>
                    </Text>
                  </Flex>
                )}
                {shift.data?.location && (
                  <Flex alignItems="center" my="1">
                    <Icon icon={MapPin} size={20} />
                    <Text ml="2">
                      {formatAddress(shift.data?.location?.address)} (
                      {shift.data?.location?.id})
                    </Text>
                  </Flex>
                )}
                {shift.data?.supervisor && (
                  <Flex alignItems="center" my="1">
                    <Icon icon={Crown} size={20} />
                    <Text ml="2">
                      Supervisor: {shift.data?.supervisor}{' '}
                      {shift.data?.supervisorPhone
                        ? `(${shift.data?.supervisorPhone})`
                        : null}
                    </Text>
                  </Flex>
                )}
                {shift.data?.startsAt && (
                  <Flex my="1" onClick={() => setIsRelative(!isRelative)}>
                    <Icon icon={Clock} size={20} />
                    <Text ml="2">
                      <Text>
                        {`${
                          moment(shift.data?.startsAt).isBefore(moment())
                            ? 'Started'
                            : 'Starts'
                        } ${moment(shift.data?.startsAt).fromNow()}`}
                      </Text>{' '}
                      {moment
                        .tz(
                          shift.data?.startsAt,
                          shift.data?.location?.address?.timezone
                        )
                        .format('LLLL')}{' '}
                      -{' '}
                      {moment
                        .tz(
                          shift.data?.endsAt,
                          shift.data?.location?.address?.timezone
                        )
                        .format('LT z')}
                      <Text>
                        Lunch break: {shift.data?.lunchLength || 'None'}
                      </Text>
                    </Text>
                  </Flex>
                )}
                {shift.data && (
                  <Flex my="1">
                    <Icon icon={DollarSign} size={20} />
                    <Box ml="2" width="100%">
                      <Pay shift={shift.data} />
                    </Box>
                  </Flex>
                )}
              </Box>
            </Box>
          )}

          {!!mustHaveRequirements?.length && (
            <Box key="mustHaveRequirements">
              <Heading level="3">
                {shift.data?.position?.name} ({shift.data?.position?.id})
              </Heading>
              <Heading level="4">Must have requirements</Heading>
              {mustHaveRequirements?.map(({ name }) => (
                <Text color="red" key={name}>
                  • {name}
                </Text>
              ))}
            </Box>
          )}

          {shift.data && (
            <Box key="supplyAnalytics">
              <Heading level="2" mr="3">
                Supply analytics
              </Heading>
              <Listings shiftId={shift.data?.id} />
            </Box>
          )}
        </Box>

        <Box flex="1 1 100%">
          {shift.data && (
            <>
              <Workers shift={shift.data} />

              <Button
                mt={2}
                block
                kind="small"
                variant="primary"
                onClick={() => setShowCallListModal(true)}
              >
                Message workers
              </Button>

              <ComposeMessageModal
                shiftId={Number(shiftId)}
                submitMessageCallback={() => setShowCallListModal(false)}
                showModal={showCallListModal}
                hideModal={() => setShowCallListModal(false)}
              />
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default ShiftDetail
