import { Table } from '@workwhile/ui'
import React, { useState } from 'react'
import * as moment from 'moment-timezone'

import api from 'api'
import CollapsibleSection from 'components/Shared/CollapsibleSection'
import { User } from 'components/Shared/common_defs'
import StatusSetter from 'components/Shared/StatusSetter'
import { keyValueColumns } from 'components/Users/keyValueColumns'
import {
  BACKGROUND_CHECK_STATUSES,
  type BackgroundCheckStatus,
} from 'types/backgroundCheck'
import { UserDrugScreenings } from './UserDrugScreenings'

interface Props {
  user: User
  refreshData: () => void
  defaultOpen: boolean
}

const PROVIDER_TEXT = 'Checkr'
const ALLOWED_STATUSES = BACKGROUND_CHECK_STATUSES
const STATUS_OPTIONS = ALLOWED_STATUSES.map((status) => {
  return { label: status, value: status }
})
const DATE_TIME_SUBMITTED_FORMAT = 'YYYY-MM-DD h:mma z'

const UserCheckrVerification = (props: Props) => {
  const { user, refreshData } = props
  const timezone = user.address?.timezone || moment.tz.guess(true)

  const [newCriminalStatus, setNewCriminalStatus] =
    useState<BackgroundCheckStatus>('manually_clear') // input defaults to manually_clear
  const [submittingCriminalStatus, setSubmittingCriminalStatus] =
    useState(false)

  const [newMvrStatus, setNewMvrStatus] =
    useState<BackgroundCheckStatus>('manually_clear') // input defaults to manually_clear
  const [submittingMvrStatus, setSubmittingMvrStatus] = useState(false)

  const providerStatusMetadata = user.checkrStatus

  const criminalStatus = providerStatusMetadata?.criminalStatus
  const criminalSubmitted = providerStatusMetadata?.criminalSubmitted
  const mvrStatus = providerStatusMetadata?.mvrStatus
  const mvrSubmitted = providerStatusMetadata?.mvrSubmitted

  const candidateId = providerStatusMetadata?.candidateId
  const hasCandidate = Boolean(candidateId)
  const setStatusDisabled = !hasCandidate

  const shouldLinkCriminal = Boolean(criminalStatus) && Boolean(candidateId)
  const shouldLinkMvr = Boolean(mvrStatus) && Boolean(candidateId)
  const hasAnyStatus = Boolean(criminalStatus) || Boolean(mvrStatus)
  const defaultOpen = props.defaultOpen && hasAnyStatus

  const data = React.useMemo(
    () => [
      {
        key: 'criminal_status',
        value: criminalStatus || 'N/A',
        link: shouldLinkCriminal
          ? `https://dashboard.checkr.com/candidates/${candidateId}`
          : null,
      },
      {
        key: 'criminal_submitted_at',
        value: criminalSubmitted
          ? moment
              .tz(criminalSubmitted, timezone)
              .format(DATE_TIME_SUBMITTED_FORMAT)
          : 'N/A',
      },
      {
        key: 'mvr_status',
        value: mvrStatus || 'N/A',
        link: shouldLinkMvr
          ? `https://dashboard.checkr.com/candidates/${candidateId}`
          : null,
      },
      {
        key: 'mvr_submitted_at',
        value: mvrSubmitted
          ? moment.tz(mvrSubmitted, timezone).format(DATE_TIME_SUBMITTED_FORMAT)
          : 'N/A',
      },
    ],
    []
  )

  const submitCriminalStatusChange = () => {
    setSubmittingCriminalStatus(true)
    const r = window.prompt(
      `You are about to set USER(${user.id}) ${PROVIDER_TEXT} Criminal status to: ${newCriminalStatus}. To execute this action, please press OK.`
    )
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/checkr`, {
          type: 'checkr',
          status: newCriminalStatus,
          reason: r,
        })
        .then((_response) => {
          alert(`${PROVIDER_TEXT} Criminal status is now ${newCriminalStatus}`)
          setSubmittingCriminalStatus(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingCriminalStatus(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingCriminalStatus(false)
    }
    return
  }

  const submitMvrStatusChange = () => {
    setSubmittingMvrStatus(true)
    const r = window.prompt(
      `You are about to set USER(${user.id}) ${PROVIDER_TEXT} MVR status to: ${newMvrStatus}. To execute this action, please press OK.`
    )
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/checkr`, {
          type: 'checkr_mvr',
          status: newMvrStatus,
          reason: r,
        })
        .then((_response) => {
          alert(`${PROVIDER_TEXT} MVR status is now ${newMvrStatus}`)
          setSubmittingMvrStatus(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingMvrStatus(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingMvrStatus(false)
    }
    return
  }

  return (
    <CollapsibleSection
      title={`${PROVIDER_TEXT} Details`}
      defaultOpen={defaultOpen}
    >
      <Table variant="borderless" data={data} columns={keyValueColumns} />
      <StatusSetter
        label={`Set ${PROVIDER_TEXT} Criminal Status`}
        disabled={setStatusDisabled}
        name="newCriminalStatus"
        value={newCriminalStatus}
        handleChange={(status) => setNewCriminalStatus(status)}
        isLoading={submittingCriminalStatus}
        submitStatusChange={submitCriminalStatusChange}
        options={STATUS_OPTIONS}
        buttonType="confirm"
      />
      <StatusSetter
        label={`Set ${PROVIDER_TEXT} MVR Status`}
        disabled={setStatusDisabled}
        name="newMvrStatus"
        value={newMvrStatus}
        handleChange={(status) => setNewMvrStatus(status)}
        isLoading={submittingMvrStatus}
        submitStatusChange={submitMvrStatusChange}
        options={STATUS_OPTIONS}
        buttonType="confirm"
      />
      <UserDrugScreenings
        drugScreenings={providerStatusMetadata.drugScreenings}
        makeReportUrl={(reportId) =>
          hasCandidate
            ? `https://dashboard.checkr.com/candidates/${encodeURIComponent(candidateId)}/reports/${encodeURIComponent(reportId)}`
            : undefined
        }
        userTimeZone={timezone}
      />
    </CollapsibleSection>
  )
}

export default UserCheckrVerification
