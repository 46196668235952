import { useMutation, useQueryClient } from '@tanstack/react-query'
import { approveI9Submission, resetI9Submission } from 'api/i9'
import { i9Keys } from './keys'
import { I9Submission } from 'api/typings'

interface ResetParams {
  userId: number
  reason: string
}

export const useApproveI9SubmissionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: approveI9Submission,
    onSuccess: (_, userId) => {
      // Immediately update the cache to remove the approved submission
      queryClient.setQueryData<I9Submission[]>(i9Keys.submissions, (old) => {
        if (!old) return []
        return old.filter((submission) => submission.userId !== userId)
      })
      // Then invalidate to refetch fresh data
      queryClient.invalidateQueries({ queryKey: i9Keys.submissions })
    },
  })
}

export const useResetI9SubmissionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ userId, reason }: ResetParams) =>
      resetI9Submission(userId, reason),
    onSuccess: (_, { userId }) => {
      // Immediately update the cache to remove the reset submission
      queryClient.setQueryData<I9Submission[]>(i9Keys.submissions, (old) => {
        if (!old) return []
        return old.filter((submission) => submission.userId !== userId)
      })
      // Then invalidate to refetch fresh data
      queryClient.invalidateQueries({ queryKey: i9Keys.submissions })
    },
  })
}
