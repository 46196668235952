import { Card, Link, Text, Timeline, useAppTheme } from '@workwhile/ui'
import { DrugScreening } from 'api/typings'
import moment from 'moment'
import React, { CSSProperties } from 'react'

const DATE_TIME_SUBMITTED_FORMAT = 'YYYY-MM-DD h:mmA z'

const capitalizeStyle: CSSProperties = { textTransform: 'capitalize' }

type DrugScreeningCardProps = {
  drugScreening: DrugScreening
  sequence: number
  makeReportUrl: (reportId: string) => string
  userTimeZone: string
}

type DrugScreeningEnum = DrugScreening['transitions'][number]['status']

const DrugScreeningCard = ({
  drugScreening,
  sequence,
  makeReportUrl,
  userTimeZone,
}: DrugScreeningCardProps) => {
  const { colors } = useAppTheme()

  const colorMap: Record<DrugScreeningEnum, string> = {
    canceled: colors.neutrals[200],
    clear: colors.success,
    consider: colors.error,
    pending: colors.neutrals[200],
    rejected: colors.error,
    suspended: colors.error,
  }

  const drugScreenTransitions = drugScreening.transitions.map((transition) => {
    const color = colorMap[transition.status] || colors.neutrals[200]
    return (
      <Timeline.Item key={transition.status} color={color}>
        <Text color="lightText" fontSize={0}>
          {moment
            .tz(transition.occurredAt, userTimeZone)
            .format(DATE_TIME_SUBMITTED_FORMAT)}
        </Text>
        <Text style={capitalizeStyle} fontSize={2}>
          {transition.status}
        </Text>
      </Timeline.Item>
    )
  })

  const reportUrl = makeReportUrl(drugScreening.reportId)

  return (
    <Card shadowless mb={2}>
      <Text>
        Drug Screening #{sequence}{' '}
        {reportUrl ? (
          <Link
            to={reportUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: '0.5rem' }}
            color="primary"
          >
            (View report)
          </Link>
        ) : null}
      </Text>
      <Text fontSize={3} style={capitalizeStyle} mt={1}>
        {drugScreening.status}
      </Text>
      <Text mt={2} color="lightText">
        History
      </Text>
      {drugScreenTransitions ? (
        <Timeline mt={2}>{drugScreenTransitions}</Timeline>
      ) : (
        <Text>No history found</Text>
      )}
    </Card>
  )
}

type UserDrugScreeningsProps = {
  drugScreenings?: DrugScreening[]
  makeReportUrl: (reportId: string) => string | undefined
  userTimeZone: string
}

export const UserDrugScreenings = ({
  drugScreenings,
  makeReportUrl,
  userTimeZone,
}: UserDrugScreeningsProps) => {
  if (drugScreenings && !drugScreenings.length) {
    return (
      <>
        <Text mb={2}>Drug Screenings</Text>
        <Text>No drug screenings found for this user</Text>
      </>
    )
  }

  const drugScreeningsCards = drugScreenings.map((drugScreening, index) => (
    <DrugScreeningCard
      key={drugScreening.reportId}
      drugScreening={drugScreening}
      sequence={index + 1}
      makeReportUrl={makeReportUrl}
      userTimeZone={userTimeZone}
    />
  ))

  return (
    <>
      <Text mb={2}>Drug Screenings</Text>
      {drugScreeningsCards}
    </>
  )
}
