import React from 'react'
import { Box, Button, Flex } from '@workwhile/ui'
import { useCompanySwitchQuery } from 'queries/company/useCompanySwitchQuery'
import { useParams, useSearchParams } from 'react-router-dom'

/**
 * This page is used to switch the company and redirect to the redirectUrl or to biz portal home page
 *
 * This should be used within Slack messages to switch company directly via URL
 */
export const CompanySwitch = () => {
  const { companyId } = useParams()
  const [searchParams] = useSearchParams()
  const redirectUrl =
    searchParams.get('redirectUrl') || 'https://business.workwhilejobs.com'
  const { refetch, error, isSuccess } = useCompanySwitchQuery(Number(companyId))

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return (
      <Box>
        Error: {error.message} <br />
        <Button type="button" onClick={() => refetch()}>
          Retry
        </Button>
      </Box>
    )
  }

  if (isSuccess) {
    window.location.replace(redirectUrl)
  }

  return (
    <Flex h="100vh" w="100vw" justify="center" align="center">
      Redirecting...
    </Flex>
  )
}
