import React, { useState } from 'react'
import { Modal, Select, Input, Box, Text } from '@workwhile/ui'

const RESET_REASONS = [
  { value: 'missing_list_b', label: 'Missing List B' },
  { value: 'missing_list_c', label: 'Missing List C' },
  { value: 'restricted_list_c', label: 'Restricted List C (DHS)' },
  { value: 'cutoff_illegible', label: 'Cutoff/Illegible document' },
  { value: 'expired_document', label: 'Expired document' },
  { value: 'modified_incorrect', label: 'Modified/incorrect document' },
  { value: 'shadow_blocked', label: 'Shadow blocked' },
  {
    value: 'non_physical_document',
    label: 'Photocopy/other non-physical document',
  },
  { value: 'name_mismatch', label: 'Name mismatch' },
  { value: 'duplicate_account', label: 'Duplicate account' },
  { value: 'fraud', label: 'Fraud' },
  { value: 'other', label: 'Other' },
] as const

type ResetReason = (typeof RESET_REASONS)[number]['value']

interface Props {
  open: boolean
  onClose: () => void
  onSubmit: (reason: string) => void
  loading?: boolean
}

export function ResetSubmissionModal({
  open,
  onClose,
  onSubmit,
  loading,
}: Props) {
  const [selectedReason, setSelectedReason] = useState<ResetReason | null>(null)
  const [otherReason, setOtherReason] = useState('')

  const handleSubmit = ({ closeModal }: { closeModal: () => void }) => {
    if (!selectedReason) return

    if (selectedReason === 'other') {
      if (!otherReason.trim()) return
      onSubmit(otherReason.trim())
    } else {
      const reasonLabel = RESET_REASONS.find(
        (r) => r.value === selectedReason
      )?.label
      onSubmit(reasonLabel || selectedReason)
    }
    closeModal()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Reset I9 Submission"
      onOk={handleSubmit}
      loading={loading}
    >
      <Box pb={8}>
        <Text mb={3}>
          Please select a reason for resetting this I9 submission:
        </Text>
        <Select
          options={RESET_REASONS}
          value={RESET_REASONS.find((r) => r.value === selectedReason)}
          onChange={(option) => {
            setSelectedReason((option as (typeof RESET_REASONS)[number]).value)
            if ((option as (typeof RESET_REASONS)[number]).value !== 'other') {
              setOtherReason('')
            }
          }}
          placeholder="Select a reason"
        />
        {selectedReason === 'other' && (
          <Box mt={3} mb={3}>
            <Input
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
              placeholder="Please specify the reason"
            />
          </Box>
        )}
      </Box>
    </Modal>
  )
}
