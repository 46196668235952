import React from 'react'
import { Box, Text, Flex } from '@workwhile/ui'

type I9DocumentPreviewProps = {
  urls?: string[]
  children?: React.ReactNode
}

export const I9DocumentPreview: React.FC<I9DocumentPreviewProps> = ({
  urls = [],
  children,
}) => {
  const handleDocumentClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      {children}
      {urls.length > 0 && (
        <Flex flexWrap="wrap" gap={3}>
          {urls.map((url, urlIndex) => (
            <Box
              key={urlIndex}
              width={300}
              height={200}
              borderRadius="8px"
              overflow="hidden"
              border="1px solid"
              borderColor="gray.200"
              position="relative"
              style={{ cursor: 'pointer' }}
              onClick={() => handleDocumentClick(url)}
            >
              <iframe
                src={`${url}#view=FitW&toolbar=0&navpanes=0`}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  pointerEvents: 'none',
                }}
                title="I9 document"
              />
              <Box
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                padding={2}
                backgroundColor="rgba(0, 0, 0, 0.5)"
                textAlign="center"
              >
                <Text color="white" cursor="pointer">
                  Open PDF
                </Text>
              </Box>
            </Box>
          ))}
        </Flex>
      )}
    </>
  )
}
