import React, { useState } from 'react'
import AddShiftBonus from './AddShiftBonus'
import { WorkShift } from '../../Shared/common_defs'
import graphql from '../../../graphql'
import Loading from '../../Shared/loading'
import * as moment from 'moment-timezone'
import { dateCommaTimeFormat, getShiftTimezone } from '../../utils/general_util'
import RefreshButton from '../../Shared/RefreshButton'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Box } from '@workwhile/ui'

interface Props {
  shift: WorkShift
}

const Pay = ({ shift }: Props) => {
  const timezone = getShiftTimezone(shift)
  const [showActions, setShowActions] = useState(false)
  const queryClient = useQueryClient()
  const pay = useQuery({
    queryKey: ['shift', shift?.id, 'pay'],
    queryFn: async () =>
      graphql(`
            admin { 
                shift (shiftId:${shift?.id}) {
                    id, numWorkersFilled, pay, payRate, payLumpSum,
                    shiftBonuses {amount, createdAt, companyPaid, isDynamicBonus, isNewWorkerBonus},
                    location {address {timezone}},
                }
            }`).then((result) => result.data.data.admin.shift),
    initialData: shift,
    enabled: !!shift?.id,
    staleTime: 10000,
    initialDataUpdatedAt: queryClient.getQueryState(['shift', shift?.id])
      ?.dataUpdatedAt,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  if (pay.isFetching) {
    return <Loading type="button" />
  }

  if (!pay.data) {
    return <p>Could not find pay info for this shift.</p>
  }

  let displayedBonus = null

  const formattedTime = (time: string) => {
    return moment.tz(time, timezone).format(dateCommaTimeFormat)
  }

  const shiftBonuses = pay.data?.shiftBonuses
  if (shiftBonuses && shiftBonuses.length > 0) {
    // Company-paid bonus information.
    const employerBonuses = shiftBonuses.filter((bonus) => !!bonus.companyPaid)
    const latestEmployerBonus =
      employerBonuses.length > 0
        ? employerBonuses[employerBonuses.length - 1]
        : null
    const latestEmployerBonusAmount = latestEmployerBonus
      ? Number(latestEmployerBonus.amount)
      : 0
    const timeEmployerBonusSet = latestEmployerBonusAmount
      ? formattedTime(latestEmployerBonus.createdAt)
      : null

    // WW Bonus information. Some bonuses are available to all workers and some are subjective.
    const wwBonuses = shiftBonuses.filter(
      (bonus) =>
        !bonus.companyPaid && !bonus.isDynamicBonus && !bonus.isNewWorkerBonus
    )
    const latestWWBonus =
      wwBonuses.length > 0 ? wwBonuses[wwBonuses.length - 1] : null
    const latestWWBonusAmount = latestWWBonus ? Number(latestWWBonus.amount) : 0
    const timeWWBonusSet = latestWWBonusAmount
      ? formattedTime(latestWWBonus.createdAt)
      : null

    // Custom/subjective bonuses.
    let specialBonusInfo = ''
    for (let bonus of shiftBonuses) {
      if (bonus.isDynamicBonus || bonus.isNewWorkerBonus) {
        specialBonusInfo += `*Selective bonus: $${bonus.amount} (${bonus.isNewWorkerBonus ? 'new worker bonus' : 'dynamic bonus'}). Set at ${formattedTime(bonus.createdAt)}\n`
      }
    }

    displayedBonus = (
      <p>
        ${latestWWBonusAmount + latestEmployerBonusAmount} bonus
        {specialBonusInfo.length > 0 ? `*` : ``} (${latestEmployerBonusAmount}{' '}
        company <i>{timeEmployerBonusSet ? `(${timeEmployerBonusSet})` : ''}</i>{' '}
        + ${latestWWBonusAmount} WW{' '}
        <i>{timeWWBonusSet ? `(${timeWWBonusSet})` : ''}</i>)
        <br />
        {specialBonusInfo ? <i>{specialBonusInfo}</i> : ''}
      </p>
    )
  }

  return (
    <Box width="100%">
      <>
        <div style={{ float: 'right' }}>
          <RefreshButton refreshFn={pay.refetch} loading={pay.isFetching} />
        </div>
        <p>
          $
          {pay.data?.payRate
            ? pay.data?.payRate + '/hr' + ' total: $' + pay.data?.pay
            : pay.data?.payLumpSum + ' for the whole shift'}
          .
        </p>
        {displayedBonus}
      </>

      <button
        className="standard-button"
        onClick={() => setShowActions(!showActions)}
        type="button"
      >
        {showActions ? 'Hide' : 'Show'} Actions
      </button>

      {showActions && <AddShiftBonus shift={shift} fetchPay={pay.refetch} />}
    </Box>
  )
}

export default Pay
