import { useQuery } from '@tanstack/react-query'
import { switchCompany } from 'api/company/switchCompany'
import { companySwitchKey } from './keys'

export function useCompanySwitchQuery(companyId: number) {
  return useQuery({
    queryKey: companySwitchKey,
    queryFn: () => switchCompany(companyId),
    staleTime: 0,
    gcTime: 0,
  })
}
