import api from 'api'

export const approveI9Submission = async (userId: number): Promise<void> => {
  await api.post(`/admin/w2/onboarding_submission/${userId}/approve`)
}

export const resetI9Submission = async (
  userId: number,
  reason: string
): Promise<void> => {
  await api.post(`/admin/w2/onboarding_submission/${userId}/reset`, { reason })
}
