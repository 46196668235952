import React, { useState, useMemo } from 'react'
import { Box, Flex, Select, Text, Card, Label, Heading } from '@workwhile/ui'
import { defs } from 'components/Shared/defs'
import { OptionTypeBase } from 'react-select'
import { Review } from './Review'
import { format, parseISO } from 'date-fns'
import { useI9SubmissionsQuery } from '../../queries/i9/useI9SubmissionsQuery'
import { Link } from 'react-router-dom'
import { DocumentPreviewProvider } from '../VerifyCredentials/DocumentPreviewContext'
import { I9Submission } from 'api/typings'

type GroupedEntry = [string, I9Submission[]]

const SearchFacet = ({
  children,
  label,
}: {
  children: React.ReactNode
  label?: string
}) => (
  <Flex
    flex="1 1 auto"
    flexDirection="column"
    pt={2}
    as="label"
    width="100%"
    style={{ whiteSpace: 'nowrap' }}
  >
    {label ? (
      <Text as="span" style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
        <Text mr="1" as="span" color={defs.mediumBlack}>
          &
        </Text>
        {label}
      </Text>
    ) : null}
    {children}
  </Flex>
)

export function I9Dashboard() {
  const [selectedState, setSelectedState] = useState<string | null>(null)
  const [selectedMarket, setSelectedMarket] = useState<string | null>(null)
  const [groupBy, setGroupBy] = useState<
    'date-asc' | 'date-desc' | 'state' | 'market'
  >('date-asc')
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)

  const { data: i9Submissions = [], isLoading, error } = useI9SubmissionsQuery()

  const { stateOptions, marketOptions } = useMemo(() => {
    if (!i9Submissions) {
      return { stateOptions: [], marketOptions: [] }
    }

    const uniqueStates = i9Submissions
      .map((s) => s.w2State)
      .filter(Boolean)
      .reduce((acc, state) => {
        if (!acc.includes(state)) {
          acc.push(state)
        }
        return acc
      }, [] as string[])

    const uniqueMarkets = i9Submissions
      .map((s) => s.market)
      .filter(Boolean)
      .reduce((acc, market) => {
        if (!acc.includes(market)) {
          acc.push(market)
        }
        return acc
      }, [] as string[])

    return {
      stateOptions: uniqueStates.map((state) => ({
        label: state,
        value: state,
      })),
      marketOptions: uniqueMarkets.map((market) => ({
        label: market,
        value: market,
      })),
    }
  }, [i9Submissions])

  const sortByDate = (a: string, b: string, ascending = true) => {
    const diff = parseISO(a).getTime() - parseISO(b).getTime()
    return ascending ? diff : -diff
  }

  const filteredData = useMemo(() => {
    return i9Submissions.filter((item) => {
      if (selectedState && item.w2State !== selectedState) return false
      if (selectedMarket && item.market !== selectedMarket) return false
      return true
    })
  }, [selectedState, selectedMarket, i9Submissions])

  const groupedData = useMemo<GroupedEntry[]>(() => {
    const grouped = filteredData.reduce(
      (acc, item) => {
        const key =
          groupBy === 'market'
            ? item.market || 'Unknown Market'
            : groupBy === 'state'
              ? item.w2State || 'Unknown State'
              : format(parseISO(item.submittedAt), 'MMMM d, yyyy')

        if (!acc[key]) acc[key] = []
        acc[key].push(item)
        return acc
      },
      {} as Record<string, I9Submission[]>
    )

    return Object.entries(grouped)
      .sort(([a], [b]) => {
        if (groupBy.startsWith('date')) {
          const dateA = parseISO(Object.values(grouped[a])[0].submittedAt)
          const dateB = parseISO(Object.values(grouped[b])[0].submittedAt)
          return groupBy === 'date-asc'
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime()
        }
        return a.localeCompare(b)
      })
      .map(([key, items]) => [
        key,
        items.sort((a, b) =>
          groupBy.startsWith('date')
            ? sortByDate(a.submittedAt, b.submittedAt, groupBy === 'date-asc')
            : sortByDate(a.submittedAt, b.submittedAt)
        ),
      ])
  }, [filteredData, groupBy])

  const renderSubmissionName = (submission: I9Submission) => (
    <Link to={`/users/${submission.userId}`}>
      {`${submission.firstName} ${submission.lastName} (${submission.userId})`}
    </Link>
  )

  const selectedSubmission = useMemo(() => {
    if (!selectedUserId || !i9Submissions?.length) return null
    return i9Submissions.find((s) => s.userId === selectedUserId)
  }, [selectedUserId, i9Submissions])

  if (isLoading) {
    return <Text padding="24px">Loading submissions...</Text>
  }

  if (error) {
    return (
      <Text padding="24px">
        Error loading submissions: {(error as Error).message}
      </Text>
    )
  }

  if (!i9Submissions?.length) {
    return <Text padding="24px">No submissions to review at this time.</Text>
  }

  return (
    <Box>
      <Flex
        style={{ columnGap: defs.marginM }}
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <Flex
          key="bar"
          flexDirection="column"
          style={{
            minWidth: '255px',
            borderRadius: defs.borderRadiusL,
          }}
          borderColor={defs.veryLightBlack}
          bg={defs.veryLightBlack}
          p={defs.paddingM}
          my={defs.paddingM}
        >
          <Flex
            key="form"
            flexDirection="column"
            style={{ rowGap: defs.marginM }}
          >
            <SearchFacet label="State">
              <Select
                options={stateOptions}
                placeholder="Select state"
                isClearable
                onChange={(option: OptionTypeBase | null) =>
                  setSelectedState(option?.value || null)
                }
              />
            </SearchFacet>
            <SearchFacet label="Market">
              <Select
                options={marketOptions}
                placeholder="Select market"
                isClearable
                onChange={(option: OptionTypeBase | null) =>
                  setSelectedMarket(option?.value || null)
                }
              />
            </SearchFacet>
          </Flex>
        </Flex>

        <Box key="results" width="100%">
          <Flex alignItems="start" mb={3}>
            <Box>
              <Heading level="4">Group by</Heading>
              <Flex>
                <Label
                  as="button"
                  type="button"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  variant={groupBy === 'date-asc' ? 'primary' : 'none'}
                  mr="2"
                  onClick={() => setGroupBy('date-asc')}
                >
                  Date asc
                </Label>
                <Label
                  as="button"
                  type="button"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  variant={groupBy === 'date-desc' ? 'primary' : 'none'}
                  mr="2"
                  onClick={() => setGroupBy('date-desc')}
                >
                  Date desc
                </Label>
                <Label
                  as="button"
                  type="button"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  variant={groupBy === 'state' ? 'primary' : 'none'}
                  mr="2"
                  onClick={() => setGroupBy('state')}
                >
                  State
                </Label>
                <Label
                  as="button"
                  type="button"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                  variant={groupBy === 'market' ? 'primary' : 'none'}
                  mr="2"
                  onClick={() => setGroupBy('market')}
                >
                  Market
                </Label>
              </Flex>
            </Box>
          </Flex>

          <Flex>
            <Box width={1 / 3} p={3}>
              <Card>
                {groupedData.map(([group, submissions]) => (
                  <Box key={group}>
                    <Heading level="4" mb={3}>
                      {group}
                    </Heading>
                    {submissions.map((submission) => (
                      <Box
                        key={submission.userId}
                        mb={3}
                        p={3}
                        backgroundColor="offWhite"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedUserId(submission.userId)}
                        borderColor={
                          selectedUserId === submission.userId
                            ? 'primary'
                            : 'transparent'
                        }
                        borderStyle="solid"
                        borderWidth={2}
                      >
                        {renderSubmissionName(submission)}
                      </Box>
                    ))}
                  </Box>
                ))}
              </Card>
            </Box>
            <Box
              width={2 / 3}
              p={3}
              style={{
                position: 'sticky',
                top: '24px',
                maxHeight: 'calc(100vh - 48px)',
                overflowY: 'auto',
              }}
            >
              {!selectedUserId ? (
                <Text>Please select a form to view details</Text>
              ) : (
                <DocumentPreviewProvider>
                  <Review submission={selectedSubmission} />
                </DocumentPreviewProvider>
              )}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
