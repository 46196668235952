import React, { useState } from 'react'
import { Listing as ListingType, WorkShift } from '../../Shared/common_defs'
import Loading from '../../Shared/loading'
import { isShiftFull, isShiftInProgress } from '../../utils/general_util'
import { Box, Button, Card, Flex, Heading, Icon, Text } from '@workwhile/ui'
import RefreshButton from 'components/Shared/RefreshButton'
import moment from 'moment'
import { useShiftDetails } from 'queries/shifts'
import { ChevronDown, ChevronUp, ExternalLink } from 'lucide-react'
import ListingNotify from '../Listings/ListingNotify'
import ListingNextTier from '../Listings/ListingNextTier'
import {
  useWorkerEligibility,
  WorkerEligibility,
} from 'queries/shifts/useWorkerEligibility'
import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import ShiftAnalytics from './ShiftAnalytics'

interface Props {
  shiftId: WorkShift['id']
}

const RANGE_STEP_MILES = 5

const Listing = ({
  listing,
  shift,
}: {
  listing: ListingType
  shift: UseQueryResult<WorkShift>
}) => {
  const queryClient = useQueryClient()
  const eligibility = queryClient
    .getQueriesData<WorkerEligibility & { min: number; max: number }>({
      queryKey: ['listing', listing.id, 'eligibility'],
    })
    .reduce(
      (prev, [queryKey, next]) =>
        !!next
          ? {
              total_market: prev.total_market + next.total_market,
              total_tier: prev.total_tier + next.total_tier,
              eligible_tier: prev.eligible_tier + next.eligible_tier,
              ineligibility_reasons: {
                ...prev.ineligibility_reasons,
                ...Object.entries(next.ineligibility_reasons).reduce(
                  (all, [key, value]) => ({
                    ...all,
                    [key]: (prev.ineligibility_reasons[key] || 0) + value,
                  }),
                  {}
                ),
              },
              /** @ts-ignore-error queryKey[3] is {min: number, max: number} */
              min: Math.min(prev.min, queryKey[3]?.min),
              /** @ts-ignore-error queryKey[3] is {min: number, max: number} */
              max: Math.max(prev.max, queryKey[3]?.max),
              listing_radius: next.listing_radius,
            }
          : prev,
      {
        total_market: 0,
        total_tier: 0,
        eligible_tier: 0,
        ineligibility_reasons: {},
        listing_radius: 75,
        min: 0,
        max: 0,
      } as WorkerEligibility & { min: number; max: number }
    )

  /** Fetch each range progressively until full radius is checked */
  const isRangeQuery = ['all', 'experienced'].includes(listing.tier)
  const workerEligibility = useWorkerEligibility(
    listing?.id,
    isRangeQuery && eligibility.listing_radius > eligibility.max
      ? {
          min: eligibility.max,
          max: eligibility.max + RANGE_STEP_MILES,
        }
      : { min: eligibility.min, max: RANGE_STEP_MILES }
  )

  const [showActions, setShowActions] = useState(false)

  const navigateToListing = () => {
    window.open(
      'https://console.cloud.google.com/logs/query;query=resource.type%3D%22gae_app%22%0Alog_name%3D%22projects%2Fworkwhile-247820%2Flogs%2Flib.observability.structured%22%0AjsonPayload.message.type%3D%22listing%22%0AjsonPayload.message.listing_id%3D%22${listing.id}%22;timeRange=P7D;summaryFields=jsonPayload%252Fmessage%252Flisting_id,jsonPayload%252Fmessage%252Fsub_type,jsonPayload%252Fmessage%252Fuser_id,jsonPayload%252Fmessage%252Ffrom_tier,jsonPayload%252Fmessage%252Fto_tier:false:32:beginning?project=workwhile-247820',
      '_blank'
    )
  }

  const shiftFull = isShiftFull(shift.data)

  const inProgress = isShiftInProgress(shift.data)

  const isListingInTempRosterHold = (listing) =>
    listing.tier === 'roster' && shift.data?.rostersHoldExpiresAt

  return (
    <Box key={listing.id}>
      <Flex alignItems="center">
        <Heading level="4">Listing ({listing.id})</Heading>
        <Button
          ml="2"
          kind="small"
          variant="text"
          onClick={navigateToListing}
          disabled={!listing.id}
        >
          GCP Logs
          <Icon
            style={{ marginLeft: '5px' }}
            size={15}
            color="primary"
            icon={ExternalLink}
          />
        </Button>
      </Flex>
      <Box>
        <Text flex="1 1 auto">
          <Text>Total in market: {eligibility?.total_market}</Text>
          <Text>
            Total in <Text as="strong">{listing.tier}</Text> tier:{' '}
            {eligibility?.total_tier}
          </Text>
          <Text>
            Eligible in <Text as="strong">{listing.tier}</Text> tier:{' '}
            {eligibility?.eligible_tier}
          </Text>
        </Text>
        <Box flex="3 1 auto">
          <Flex alignItems="center" justifyContent="space-between">
            <Text>
              {`Top ineligibility reasons for `}
              <Text as="strong">{listing.tier}</Text>
              {` tier`}
              {isRangeQuery && (
                <Text as="strong">{` within ${eligibility.max} mi `}</Text>
              )}
              <Text as="span">:</Text>
            </Text>
            <Flex alignItems="center">
              {workerEligibility.isFetching && (
                <Text style={{ fontStyle: 'italic' }}>
                  {isRangeQuery
                    ? `Loading ${eligibility.max}-${eligibility.max + RANGE_STEP_MILES} of ${eligibility.listing_radius} miles...`
                    : `Loading ${listing.tier} tier...`}
                </Text>
              )}
              <RefreshButton
                refreshFn={workerEligibility.refetch}
                loading={workerEligibility.isFetching}
              />
            </Flex>
          </Flex>
          <Box key="comprehensiveChecks">
            {eligibility?.ineligibility_reasons && (
              <>
                {Object.entries(eligibility?.ineligibility_reasons || {})
                  .sort((a, b) => b[1] - a[1])
                  .map(([reason, count]) => (
                    <Flex ml="2" justifyContent="space-between" key={reason}>
                      <Text ml="2" key={reason}>
                        {reason}
                      </Text>
                      <Text ml="2">{count}</Text>
                    </Flex>
                  ))}
              </>
            )}
          </Box>
        </Box>
      </Box>

      {!shiftFull && !inProgress && (
        <Box>
          <Button
            my="2"
            type="button"
            onClick={() => setShowActions(!showActions)}
            variant="secondary"
            kind="small"
            block
          >
            {showActions ? 'Hide' : 'Show'} actions{' '}
            <Icon
              color="primary"
              icon={showActions ? ChevronUp : ChevronDown}
            />
          </Button>

          {showActions && (
            <Card mb="2">
              <ListingNotify listing={listing} fetchListings={shift.refetch} />
              <hr />
              <ListingNextTier
                listing={listing}
                fetchListings={shift.refetch}
              />
            </Card>
          )}
        </Box>
      )}
      {isListingInTempRosterHold(listing) && (
        <Text as="strong">
          {`Listing will exit roster tier ${moment(shift.data?.rostersHoldExpiresAt).fromNow()}`}
        </Text>
      )}
    </Box>
  )
}

const Listings = ({ shiftId }: Props) => {
  const shift = useShiftDetails(shiftId, 'listingTiers')

  if (shift.isLoading) {
    return <Loading type="button" />
  }

  if (shift.isFetched && !shift.data?.listings) {
    return <Text>Could not find listings info for this shift.</Text>
  }

  const listings =
    shift.data?.listings || (shift.data?.listing && [shift.data.listing]) || []

  return (
    <Box width="100%">
      {listings?.map((listing) => (
        <Listing key={listing.id} listing={listing} shift={shift} />
      ))}
      <ShiftAnalytics />
    </Box>
  )
}

export default Listings
