import React, { useState } from 'react'
import graphql from '../../../graphql'
import WorkerList from './WorkerList'
import { getWaitlistCount } from '../shifts'
import Loading from '../../Shared/loading'
import AssignShift from './assign_shift'
import { WorkShift } from '../../Shared/common_defs'
import RefreshButton from '../../Shared/RefreshButton'
import { defs } from '../../Shared/defs'
import ShiftRosters from './ShiftRosters'
import { useQuery, useQueryClient } from '@tanstack/react-query'

interface Props {
  shift: WorkShift
}

const Workers = ({ shift }: Props) => {
  const [showWorkerList, setShowWorkerList] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const queryClient = useQueryClient()

  // given a shift id, get top level stats from backend:
  // Needed, Cushioned, Scheduled, Started, Finished
  // Waitlist, rejected, removed
  const workStats = useQuery({
    queryKey: ['shift', shift?.id, 'workStats'],
    enabled: !!shift?.id,
    queryFn: async () =>
      await graphql(`
            admin {
                shift (shiftId:${shift?.id}) {
                    id, cancelledAt, workersNeeded, numCushionWorkersNeeded, numWorkersScheduled,
                    numWorkersStarted, numWorkersFinished(excludeEmployerCancelled:true), numWorkersRejected, numWorkersRemoved,
                    rosters {
                      id, isSpecificWorkerRequest, name,
                      workers {
                          id, name, profilePicUrl
                      }
                    },
                    rostersHoldExpiresAt,
                    listings { numWaitlist },
                }
            }`).then((result) => result.data.data.admin.shift),
    initialData: shift || queryClient.getQueryData(['shift', shift?.id]),
    staleTime: 10000,
    initialDataUpdatedAt: queryClient.getQueryState(['shift', shift?.id])
      ?.dataUpdatedAt,
  })

  const getShiftLeads = () => {
    if (!shift.leadShifts || shift.leadShifts.length === 0) {
      return null
    }

    return shift.leadShifts.map((leadShift, index) => (
      <div key={index} style={{ marginBottom: defs.marginL }}>
        <b>Shift Lead Shift {leadShift.id}</b>
        <WorkerList shift={leadShift} fetchWorkStats={workStats.refetch} />
      </div>
    ))
  }

  if (workStats.isFetching) {
    return <Loading type="button" />
  }

  if (!workStats.data) {
    return <p>Could not find info on workers for this shift.</p>
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <div>
          <p>
            {shift.isTryout && (
              <span>
                <b>TRYOUT</b> |{' '}
              </span>
            )}
            {!!workStats.data.cancelledAt && (
              <span>
                <b>CANCELLED</b> |{' '}
              </span>
            )}
            <b>NEEDED: {workStats.data.workersNeeded}</b> |{' '}
            <b>CUSHIONED: {workStats.data.numCushionWorkersNeeded} </b> |{' '}
            <b>SCHEDULED: {workStats.data.numWorkersScheduled}</b> |{' '}
            <b>STARTED: {workStats.data.numWorkersStarted}</b> |{' '}
            <b>FINISHED: {workStats.data.numWorkersFinished}</b>
          </p>
          {workStats.data.rosters && workStats.data.rosters.length > 0 ? (
            <ShiftRosters
              shift={shift}
              rosters={workStats.data.rosters}
              rostersHoldExpiresAt={workStats.data.rostersHoldExpiresAt}
            />
          ) : null}
          <p>
            WAITLIST: {getWaitlistCount(workStats.data)}
            <br />
            REJECTED: {workStats.data.numWorkersRejected} | REMOVED:
            {workStats.data.numWorkersRemoved}
          </p>
        </div>
        <RefreshButton
          refreshFn={workStats.refetch}
          loading={workStats.isFetching}
        />
      </div>

      {getShiftLeads()}

      <button
        className="standard-button"
        onClick={() => setShowWorkerList(!showWorkerList)}
        type="button"
      >
        {showWorkerList ? 'Hide' : 'Show'} Workers
      </button>

      {showWorkerList && (
        <WorkerList shift={shift} fetchWorkStats={workStats.refetch} />
      )}

      <button
        className="standard-button"
        onClick={() => setShowActions(!showActions)}
        type="button"
      >
        {showActions ? 'Hide' : 'Show'} Actions
      </button>

      <button
        className="standard-button"
        onClick={() =>
          window.open(`/shift_analytics?shiftId=${shift.id}`, '_blank')
        }
        type="button"
      >
        Show Shift Fill Analytics
      </button>

      {showActions && (
        <>
          <br />
          <br />
          <AssignShift shiftId={shift.id} callback={workStats.refetch} />
        </>
      )}
    </div>
  )
}

export default Workers
