import { useQuery } from '@tanstack/react-query'
import api from 'api'
import { toCamelcaseKeys } from 'lib/string'
import { I9Submission } from 'api/typings'
import { i9Keys } from './keys'

const fetchSubmissions = async (): Promise<I9Submission[]> => {
  const response = await api.get(
    'admin/w2/onboarding_submission?include_user_status=true'
  )
  return response.data.map((item) => toCamelcaseKeys(item, { deep: true }))
}

export const useI9SubmissionsQuery = () => {
  return useQuery({
    queryKey: i9Keys.submissions,
    queryFn: fetchSubmissions,
  })
}
